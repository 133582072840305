import { useDispatch} from "react-redux";
import { Button, Headline} from "@telegram-apps/telegram-ui";
import React, { useState } from "react";
import {setMajority, setShowErrorModal} from "../../slices/app/reducer";
import {setRefIsActiveApi, updateUserApi} from "../../helpers/real_api_helper";


const AlertModal = () => {
    const [isVisible, setIsVisible] = useState(true);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleYes = () => {
        let data = {
            majority: true
        }
        setLoading(true)
        updateUserApi(data)
            .then(res => {
                dispatch(setMajority(true))
                setRefIsActiveApi()
            })
            .catch(err => {
                dispatch(setShowErrorModal('Error occured. Contact with customer support'))
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const handleNo = () => {
        window.Telegram.WebApp.close()
    }

    return (
        <div className={`custom-overlay ${isVisible ? "show" : "hide"}`}>
            <div className={`custom-modal ${isVisible ? "fade-in" : "fade-out"}`}>
                <div className={"d-flex b-8 justify-content-center flex-column p-3 w-75 modal-block main-bg"}>
                    <div className={"d-flex w-100 flex-column gap-3"}>
                        <div className={"custom-modal-body gap-2 flex-column d-flex text-center"}>
                            <Headline>Are you 18 years old and do you agree to follow <a style={{color: "#719bd9"}} href={"https://t.me/lotty/278"} target={"_blank"}>The Lotty Laws</a> ?</Headline>
                        </div>
                        <div className={"custom-modal-footer d-flex gap-2"}>
                            <Button onClick={handleNo} stretched mode={"bezeled"}>
                                No
                            </Button>
                            <Button
                                loading={loading}
                                onClick={handleYes}
                                stretched
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertModal