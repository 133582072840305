export const lotty_styles = [
    { picture: "lotty1.svg", backgroundColor: "#335e74", style_number: 1 },
    { picture: "lotty2.svg", backgroundColor: "#005e90", style_number: 2 },
    { picture: "lotty3.svg", backgroundColor: "#1e416f", style_number: 3 },
    { picture: "lotty4.svg", backgroundColor: "#2c6aac", style_number: 4 },
    { picture: "lotty5.svg", backgroundColor: "#313a6e", style_number: 5 },
    { picture: "lotty6.svg", backgroundColor: "#005b8c", style_number: 6 },
    { picture: "lotty7.svg", backgroundColor: "#c38323", style_number: 7 },
    { picture: "lotty8.svg", backgroundColor: "#b27820", style_number: 8 },
    { picture: "lotty9.svg", backgroundColor: "#926229", style_number: 9 },
    { picture: "lotty10.svg", backgroundColor: "#a5871a", style_number: 10 },
    { picture: "lotty11.svg", backgroundColor: "#875a43", style_number: 11 },
    { picture: "lotty12.svg", backgroundColor: "#713f0c", style_number: 12 },
    { picture: "lotty13.svg", backgroundColor: "#2a2a2a", style_number: 13 },
    { picture: "lotty14.svg", backgroundColor: "#4e4e4e", style_number: 14 },
    { picture: "lotty15.svg", backgroundColor: "#373737", style_number: 15 },
    { picture: "lotty16.svg", backgroundColor: "#5c5c5c", style_number: 16 },
    { picture: "lotty17.svg", backgroundColor: "#353535", style_number: 17 },
    { picture: "lotty18.svg", backgroundColor: "#515151", style_number: 18 },
    { picture: "lotty19.svg", backgroundColor: "#718233", style_number: 19 },
    { picture: "lotty20.svg", backgroundColor: "#5e760d", style_number: 20 },
    { picture: "lotty21.svg", backgroundColor: "#466c30", style_number: 21 },
    { picture: "lotty22.svg", backgroundColor: "#427449", style_number: 22 },
    { picture: "lotty23.svg", backgroundColor: "#387b4a", style_number: 23 },
    { picture: "lotty24.svg", backgroundColor: "#386c44", style_number: 24 },
    { picture: "lotty25.svg", backgroundColor: "#821c1c", style_number: 25 },
    { picture: "lotty26.svg", backgroundColor: "#913939", style_number: 26 },
    { picture: "lotty27.svg", backgroundColor: "#712e2b", style_number: 27 },
    { picture: "lotty28.svg", backgroundColor: "#65231d", style_number: 28 },
    { picture: "lotty29.svg", backgroundColor: "#a13e47", style_number: 29 },
    { picture: "lotty30.svg", backgroundColor: "#85260e", style_number: 30 },
    { picture: "lotty31.svg", backgroundColor: "#452a74", style_number: 31 },
    { picture: "lotty32.svg", backgroundColor: "#564279", style_number: 32 },
    { picture: "lotty33.svg", backgroundColor: "#6e4790", style_number: 33 },
    { picture: "lotty34.svg", backgroundColor: "#472a80", style_number: 34 },
    { picture: "lotty35.svg", backgroundColor: "#402a66", style_number: 35 },
    { picture: "lotty36.svg", backgroundColor: "#523a78", style_number: 36 },
];