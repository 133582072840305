import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { authProtectedRoutes } from "./authRoutes";
import Layout from "../Layouts";
import AuthProtected from "./AuthProtected";
import { SocketProvider } from "../context/SocketProvider";
import SocketEventListener from "../Components/custom/SocketEventListener";
import { AnimatePresence, motion } from "framer-motion";
import ErrorHandler from "../Components/custom/ErrorHandler";
import HandlePayments from "../Components/custom/HandlePayments";

const Index = () => {
    const location = useLocation();

    return (
        <AuthProtected>
            <ErrorHandler>
                <SocketProvider>
                    <SocketEventListener />
                    <React.Fragment>
                        {/*<AnimatePresence mode={'wait'}>*/}
                        <Routes location={location} key={location.pathname}>
                            {authProtectedRoutes.map((route, index) => (
                                <Route
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <motion.div
                                                key={location.pathname} // Уникальный ключ для анимации при смене маршрутов
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {route.component}
                                            </motion.div>
                                        </Layout>
                                    }
                                    key={index}
                                    exact
                                />
                            ))}
                        </Routes>
                        {/*</AnimatePresence>*/}
                    </React.Fragment>
                </SocketProvider>
            </ErrorHandler>
        </AuthProtected>
    );
};

export default Index;