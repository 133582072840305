import { Button, Card, Subheadline, Title } from "@telegram-apps/telegram-ui";
import IconTelegramPremium from "../icons/IconTelegramPremium";
import IconLottyCoin from "../icons/IconLottyCoin";
import IconTelegramStar from "../icons/IconTelegramStar";
import React, {useEffect} from "react";
import {payWithTelegramStars} from "../../slices/app/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createLogApi, updateSubscriptionApi} from "../../helpers/real_api_helper";
import {useNavigate} from "react-router-dom";
import {setShowErrorModal} from "../../slices/app/reducer";
import {setErrorCode} from "../../slices/error/reducer";

const AccessForbidden = () => {
    const {user} = useSelector(state => state.Auth)
    const {telegramStarsLoading} = useSelector(state => state.App)
    const dispatch = useDispatch()



    const sendTelegramInvoice = (type) => {
        let payload = JSON.stringify({
            user_id: user.id,
            type: type
        })
        dispatch(payWithTelegramStars(type, payload))
    }

    const payWithYellowStars = (url) => {
        let data = {
            invoice_link: url
        }
        updateSubscriptionApi(data)
            .then(res => {
                dispatch(setErrorCode(null))
            })
            .catch(err => {
                dispatch(setShowErrorModal(err.data.message))
            })
    }

    useEffect(() => {
        const handleEvent = (res) => {
            if (res.status === 'paid') {
                payWithYellowStars(res.url)
            }
        };

        window.Telegram.WebApp.onEvent('invoiceClosed', handleEvent);

        return () => {
            window.Telegram.WebApp.offEvent('invoiceClosed', handleEvent);
        };
    }, [payWithYellowStars]);

    return (
        <div className={"d-block min-vh-100 overflow-scroll"}>
            <div className={"d-flex flex-column gap-3 p-3"}>
                <div className={"d-flex align-items-center flex-column p-3 gap-3"}>
                    <div className={"block-icon disabled"}>
                        <IconLottyCoin
                            style={{
                                width: 70,
                                height: 70
                            }}
                        />
                    </div>
                    <div className={"d-flex flex-column align-items-center gap-2"}>
                        <Title className={"text-center"}>Exclusive Access for Premium Players</Title>
                        <Subheadline className={"subtitle-color text-center"}>
                            Available only for Lotty Premium and Telegram Premium users in your region. Unlock special games, rare prizes, and ultimate perks!                        </Subheadline>
                    </div>
                    <div className={"action-btn w-75 d-flex flex-column gap-2"}>
                        <Button
                            stretched
                            disabled={telegramStarsLoading}
                            mode={'gray'}
                            style={{
                                color: '#fdb345'
                            }}
                            loading={telegramStarsLoading}
                            onClick={() => sendTelegramInvoice('lotty_subscription_daily')}
                            before={
                                <IconTelegramStar
                                    style={{
                                        height: 20,
                                        width: 20
                                    }}
                                />
                            }
                            className={"grey-button"}
                        >
                            Pay 10 per day
                        </Button>
                        <Button
                            stretched
                            disabled={telegramStarsLoading}
                            mode={'gray'}
                            style={{
                                color: '#fdb345'
                            }}
                            loading={telegramStarsLoading}
                            onClick={() => sendTelegramInvoice('lotty_subscription_monthly')}
                            before={
                                <IconTelegramStar
                                    style={{
                                        height: 20,
                                        width: 20
                                    }}
                                />
                            }
                            className={"grey-button"}
                        >
                            Pay 99 per month
                        </Button>
                        <Button
                            stretched
                            disabled={telegramStarsLoading}
                            mode={'gray'}
                            style={{
                                color: '#fdb345'
                            }}
                            loading={telegramStarsLoading}
                            onClick={() => sendTelegramInvoice('lotty_subscription_yearly')}
                            before={
                                <IconTelegramStar
                                    style={{
                                        height: 20,
                                        width: 20
                                    }}
                                />
                            }
                            className={"grey-button"}
                        >
                            Pay 1099 per year
                        </Button>
                    </div>
                </div>
                <Card className={"p-3"}>
                    <div className={"d-flex align-items-center flex-column gap-3"}>
                        <div className={"block-icon"}>
                            <IconTelegramPremium
                                style={{
                                    width: 80,
                                    height: 80
                                }}
                            />
                        </div>
                        <div className={"d-flex flex-column align-items-center gap-2"}>
                            <Title>Telegram Premium</Title>
                            <Subheadline className={"subtitle-color text-center"}>
                                Use your cryptowallet to acquire
                                Telegram Premium subscription
                                for yourself or your friends.
                            </Subheadline>
                        </div>
                        <div className={"action-btn w-75"}>
                            <Button
                                stretched
                                Component={"a"}
                                href={"https://fragment.com/premium"}
                                target={"_blank"}
                            >
                                Buy Telegram Premium
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>

    );
};

export default AccessForbidden;