import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    currentTab: "lottys",
    showConfetti: false,
    showWelcomeModal: false,
    reward: false,
    counters : null,
    rewardUpdateKey: false,
    telegramStarsLoading : false,
    finalModalData: false,
    animationCache: {},
    increaseChanceCoins: null,
    resetChanceSlider: false,
    telegramInvoiceLink: null,
    planetDeg: 0,
    itemsVW: 0,
    showPrepareWinning: false,
    showFinalWinning: false,
    showErrorModal: false,
    errorTitle: 'Attention!',
    majority: null
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
       setCurrentTabbarItem(state, action) {
           state.currentTab = action.payload
       },
        setShowConfetti(state, action) {
            state.showConfetti = action.payload
        },
        setWelcomeModal(state, action) {
            state.showWelcomeModal = action.payload
        },
        setReward(state, action) {
            state.reward = action.payload
            state.rewardUpdateKey = Math.random()
        },
        setCounters(state, action) {
            state.counters = action.payload
        },
        setTelegramStarsLoading(state, action) {
           state.telegramStarsLoading = action.payload
        },
        setFinalModalData(state, action) {
           state.finalModalData = action.payload
        },
        setIncreaseChanceCoins(state, action) {
            state.increaseChanceCoins = action.payload
        },
        setResetChance(state, action) {
            state.resetChanceSlider = action.payload
        },
        setTelegramInvoiceLink(state, action) {
            state.telegramInvoiceLink = action.payload
        },
        setPlanetDeg(state, action) {
            state.planetDeg = action.payload
        },
        setItemsVW(state, action) {
            state.itemsVW = action.payload
        },
        setAnimationCache(state, action) {
            // Сохраняем кэш анимаций
            const { src, url } = action.payload; // Получаем src и url из payload
            state.animationCache = {
                ...state.animationCache, // Сохраняем существующий кэш
                [src]: url, // Добавляем новую анимацию в кэш
            };
        },
        setShowPrepareWinning(state, action) {
           state.showPrepareWinning = action.payload
        },
        setShowFinalWinning(state, action) {
            state.showFinalWinning = action.payload
        },
        setShowErrorModal(state, action) {
            state.showErrorModal = action.payload
        },
        setErrorTitle(state, action) {
            state.errorTitle = action.payload
        },
        setMajority(state, action) {
            state.majority = action.payload
        }
    }
})

export const {
    setCurrentTabbarItem,
    setShowConfetti,
    setWelcomeModal,
    setReward,
    setCounters,
    setTelegramStarsLoading,
    setFinalModalData,
    setAnimationCache,
    setIncreaseChanceCoins,
    setResetChance,
    setTelegramInvoiceLink,
    setShowPrepareWinning,
    setShowFinalWinning,
    setShowErrorModal,
    setErrorTitle,
    setMajority
} = appSlice.actions

export default appSlice.reducer