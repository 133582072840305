import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@telegram-apps/telegram-ui";
import { authUser } from "../slices/auth/thunk";

const AuthProtected = ({ children }) => {
    const dispatch = useDispatch();
    const { user, loaded } = useSelector((state) => state.Auth);

    useEffect(() => {
        if (window.Telegram?.WebApp && !user && !loaded) {
            const { Telegram } = window;
            const data_string = Telegram.WebApp.initData;
            const unsafe_data = Telegram.WebApp.initDataUnsafe;
            dispatch(authUser(data_string, unsafe_data));
        }
    }, [dispatch, user, loaded]);

    if (!loaded) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100 tg-bg">
                <Spinner size="l" />
            </div>
        );
    }

    if (user?.id) {
        return <>{children}</>;
    }

    return <div>Error: User authentication failed</div>;
};

export default AuthProtected;