import IconTon from "../../../icons/IconTon";
import {formatNumber} from "../../../../helpers/format_helper";
import IconLottyStar from "../../../icons/IconLottyStar";
import React from "react";
import IconLottyCoin from "../../../icons/IconLottyCoin";
import {Caption, Skeleton} from "@telegram-apps/telegram-ui";
import LazyDotLottie from "../../LazyDotLottie";
import cake from "../../../../assets/lottie/dotlottie/cake.lottie"

const Prizes = ({prizes, skeleton, level = 1}) => {
    return (
        <>
            {prizes && prizes
                .filter((el) => {
                    if (el.name === "Coins" && prizes.length > 3) {
                        return false;
                    }
                    return true;
                })
                .map((el, i) => {
                    switch (el.name) {
                        case "TON":
                            return (
                                <Skeleton key={i} visible={skeleton}>
                                    <div
                                        className={"d-flex gap-1 align-items-center flex-grow-1"}>
                                        <IconTon style={{
                                            height: 12
                                        }}/>
                                        <Caption
                                            level={level}
                                            className={"ton_num"}
                                        >{formatNumber(el.amount)}</Caption>
                                    </div>
                                </Skeleton>
                            );
                        case "Cakes":
                            return (
                                <Skeleton key={i} visible={skeleton}>
                                    <div
                                        className={"d-flex gap-1 align-items-center flex-grow-1"}>
                                        <LazyDotLottie
                                            src={cake}
                                            loop
                                            autoplay
                                            style={{
                                                height: 12,
                                                width: 12
                                            }}
                                        />
                                        <Caption
                                            level={level}
                                            className={"ton_num"}
                                        >{formatNumber(el.amount)}</Caption>
                                    </div>
                                </Skeleton>
                            );
                        case "Stars":
                            return (
                                <Skeleton key={i} visible={skeleton}>
                                    <div key={i} className={"d-flex gap-1 align-items-center flex-grow-1 "}>
                                        <IconLottyStar
                                            style={{
                                                height: 12
                                            }}
                                        />
                                        <Caption
                                            level={level}
                                            className={"ton_num"}
                                        >{formatNumber(el.amount)}</Caption>
                                    </div>
                                </Skeleton>
                            );
                        case "Coins":
                            return (
                                <Skeleton key={i} visible={skeleton}>
                                    <div key={i} className={"d-flex gap-1 align-items-center flex-grow-1"}>
                                        <IconLottyCoin
                                            style={{
                                                height: 12
                                            }}
                                        />
                                        <Caption
                                            level={level}
                                            className={"ton_num"}
                                        >{formatNumber(el.amount)}</Caption>
                                    </div>
                                </Skeleton>
                            );
                        default:
                            return '';
                    }
                })}
        </>
    );
}

export default Prizes