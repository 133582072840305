import {Badge, Caption, Card, FixedLayout, Headline, LargeTitle, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import WinnerTable from "../../../../pages/Home/WinnerTable";
import Drawer from "../../Drawer";
import {formatNumber} from "../../../../helpers/format_helper";
import {getPrize} from "../../../../helpers/raffle_helper";
import Prizes from "../Parts/Prizes";
import MemoizedAvatar from "../../Profile/MemoizedAvatar";
import TicketNumber from "../Parts/TicketNumber";
import {useSelector} from "react-redux";
import TunedCard from "../Parts/TunedCard";
import IconTelegramPremium from "../../../icons/IconTelegramPremium";

const ModalRaffleWinner = (props) => {
    const {isOpen, modalClose, premiumModalNotification} = props
    const {selectedRaffle} = useSelector((state) => state.Raffles);

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div
                style={{
                    paddingBottom: 75
                }}
                className={"d-flex w-100 flex-column gap-3 px-3 pt-3 justify-content-center align-items-center"}>


                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                    <Caption className={"ton_num d-flex align-items-center gap-1"}>
                        <span>
                            👥
                        </span>
                         {formatNumber(selectedRaffle?.current_participants)}
                    </Caption>

                </div>
                <div className={"task-icon"}>
                    <MemoizedAvatar src={"/images/winner_list2.png"} size={100} rounded={false} />
                </div>
                <div className={"d-flex gap-1 align-items-center"}>
                    <Title>Lotty #{selectedRaffle?.id}</Title>
                    {selectedRaffle?.is_premium && <IconTelegramPremium onClick={() => premiumModalNotification()} style={{
                        width: 20
                    }} />}
                </div>
                <LargeTitle weight={1}>
                    <div className={"d-flex justify-content-center align-items-center winner-prize"}>
                        {getPrize(selectedRaffle?.current_user_win?.prize?.type, selectedRaffle?.current_user_win?.amount, true, false)}
                    </div>
                </LargeTitle>
                <div className={"d-flex gap-2 w-100"}>
                    <div className={"w-50"}>
                        <TicketNumber selectedRaffle={selectedRaffle} />
                    </div>
                    <div className={"w-50"}>
                        <TunedCard>
                            <LargeTitle weight={1}>{selectedRaffle?.current_user_win?.place}</LargeTitle>
                        </TunedCard>
                    </div>
                </div>
                <div className={"text-center"}>
                    <Headline
                        className={"text-center"}
                        level="1"
                        weight="2"
                    >
                        List of winners
                    </Headline>
                    <Caption className={"subtitle-color"}>{selectedRaffle?.updated_at}</Caption>
                </div>
                <WinnerTable
                    selectedRaffle={selectedRaffle}
                />
            </div>

            <FixedLayout
                style={{
                    paddingBottom : "calc(var(--safe-bottom-padding))!important"
                }}
                className={"safe-padding-bottom main-bg super-z-index"}
                vertical="bottom">
            <div
                className={"p-3 px-4 d-flex w-100 justify-content-between align-items-center main-bg"}
            >
                <div className={"d-flex gap-1 align-items-center"}>
                    <span className={"d-flex"}>{selectedRaffle?.current_user_win?.place}.</span>
                    <span className={"text-truncate"} style={{maxWidth: '175px'}}>
                        {selectedRaffle?.current_user_win?.user?.username}
                    </span>
                    <Badge type={"number"}>You</Badge>
                </div>
                <div>
                    {getPrize(selectedRaffle?.current_user_win?.prize?.type, selectedRaffle?.current_user_win?.amount)}
                </div>
            </div>
            </FixedLayout>
        </Drawer>
    )
}

export default ModalRaffleWinner