import {authTgUser} from "../../helpers/real_api_helper";
import {loginError, loginSuccess} from "./reducer";
import {setMajority, setWelcomeModal} from "../app/reducer";
import {setUserStatInfo} from "../users/reducer";

export const authUser = (data, unsafeData) => async (dispatch) => {
    let json = {
        query_string: data
    }
    authTgUser(json)
        .then(async (res) => {
            let token = res.data.token
            localStorage.setItem('authToken', token)
            await dispatch(loginSuccess(res.data.user))
            await dispatch(setWelcomeModal(res.data.show_welcome_stories))
            await dispatch(setMajority(res.data.majority))
            await dispatch(setUserStatInfo(res.data.userStat))
        })
        .catch((error) => {
            dispatch(loginError(error.data))
        })

}