import React, { useEffect, useState, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all";

const StarsParticlesInCard = React.memo(({uniqueKey, position = {x: 50, y:25}}) => {
    const [init, setInit] = useState(false);

    // Асинхронная инициализация tsParticles
    useEffect(() => {
        const initializeParticles = async () => {
            try {
                await initParticlesEngine(async (engine) => {
                    await loadAll(engine); // Инициализируем tsParticles с полным набором функций
                });
                setInit(true); // Частицы загружены
            } catch (error) {
                console.error("Ошибка при инициализации tsParticles:", error);
            }
        };

        initializeParticles();
    }, []);


    const options = useMemo(() => ({
        fullScreen: {
            enable: false,
            zIndex: -1,
        },
        particles: {
            color: { value: "#e05bff" },
            move: {
                enable: true,
                speed: 2, // Скорость движения
                angle: {
                    value: 60, // Угол: горизонтальное движение
                    offset: 0,
                },
                randomMovement: true,
                direction: "random", // Направление влево
                outModes: {
                    left: "destroy", // Удаляет частицы при выходе влево
                    right: "destroy", // Удаляет частицы при выходе вправо
                    top: "none",
                    bottom: "none", // Блокирует выход вниз
                },
                // gravity: { enable: false }, // Отключаем гравитацию
                // straight: true, // Прямолинейное движение
            },
            number: {
                value: 0, // Эмиттер управляет количеством частиц
            },
            shape: {
                type: "images",
                options: {
                    images: [{ src: "images/tg_prem.svg", width: 20, height: 20 }],
                },
            },
            size: {
                value: {
                    min: 2,
                    max: 10
                }
            },
        },
        fpsLimit: 120,
        retinaDetect: true,
        emitters: [
            {
                particles: {
                    opacity: {
                        animation: {
                            enable: true,
                            speed: 1,
                            sync: true,
                            startValue: "max",
                            count: 1,
                            destroy: "min"
                        },
                        value: {
                            min: 1,
                            max: 0
                        }
                    },
                },
                position: position,
                rate: {
                    delay: 0.15, // Частота генерации частиц
                    quantity: 0.1, // Количество частиц за раз
                },
                size: { width: 0, height: 0 },
                direction: "left", // Генерация частиц только влево
                life: { duration: 0.01, count: 0 },
            },
            {
                particles: {
                    opacity: {
                        animation: {
                            enable: true,
                            speed: 1,
                            sync: true,
                            startValue: "max",
                            count: 1,
                            destroy: "min"
                        },
                        value: {
                            min: 1,
                            max: 0
                        }
                    },
                },
                position: position,
                rate: {
                    delay: 0.15,
                    quantity: 0.1,
                },
                size: { width: 0, height: 0 },
                direction: "right", // Генерация частиц только вправо
                life: { duration: 0.01, count: 0 },
            },
        ],
    }), []);



    const particlesLoaded = useMemo(() => (container) => {
        // console.log(container);
    }, []); // Мемоизируем функцию particlesLoaded

    if (init) {
        return (
            <Particles
                id={`tsparticles-${uniqueKey}`}
                particlesLoaded={particlesLoaded}
                options={options}
            />
        );
    }

    return null; // Пока не загружены, не рендерим компонент
});

export default StarsParticlesInCard;