import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkHeartbeat } from "../../helpers/real_api_helper";
import AccessForbidden from "./AccessForbidden";
import { setErrorCode } from "../../slices/error/reducer";
import {Spinner} from "@telegram-apps/telegram-ui";

const ErrorHandler = ({ children }) => {
    const dispatch = useDispatch();
    const errorCode = useSelector((state) => state.Error.errorCode);
    const user = useSelector((state) => state.Auth.user);

    // Локальный стейт для ожидания heartbeat
    const [isHeartbeatChecked, setIsHeartbeatChecked] = useState(false);

    useEffect(() => {
        if (user) {
            checkHeartbeat()
                .then(() => {
                    setIsHeartbeatChecked(true);  // Успешный пинг, помечаем как завершенное
                })
                .catch(err => {
                    dispatch(setErrorCode(err.status));  // Устанавливаем код ошибки
                    setIsHeartbeatChecked(true);  // Переходим в состояние завершения, чтобы не зацикливать ожидание
                });
        }
    }, [user, dispatch]);

    // Ждем, пока выполнится проверка heartbeat
    if (!isHeartbeatChecked) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100 tg-bg">
                <Spinner size="l" />
            </div>
        );
    }

    // Если ошибка 403, показываем компонент с ошибкой доступа
    if (errorCode === 403) {
        return <AccessForbidden />;
    }

    // Рендерим дочерние компоненты после завершения проверки
    return <>{children}</>;
};

export default ErrorHandler;