import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
   error: null,
   errorCode: null
}

const authSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload
        },
        setErrorCode(state, action) {
            state.errorCode = action.payload
        }
    }
})

export const {
    setError,
    setErrorCode
} = authSlice.actions

export default authSlice.reducer