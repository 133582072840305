// import one from "../assets/lottie/dotlottie/1.lottie";
// import two from "../assets/lottie/dotlottie/2.lottie";
// import three from "../assets/lottie/dotlottie/3.lottie";
// import React from "react";
// import MemoDotLottie from "../Components/custom/MemoDotLottie";


import IconLottyStar from "../Components/icons/IconLottyStar";
import IconTon from "../Components/icons/IconTon";
import IconLottyCoin from "../Components/icons/IconLottyCoin";
import IconTelegramStar from "../Components/icons/IconTelegramStar";
import IconLottyTicket from "../Components/icons/IconLottyTicket";
import IconLottyPremiumTicket from "../Components/icons/IconLottyPremiumTicket";
import React from "react";

export const formatNumber = (num) => {
    if (!num) return '0';

    if (num >= 10000 && num < 1000000) {
        return parseFloat((num / 1000).toFixed(1)).toString() + 'K'; // Пример: 15300 -> 15.3K
    } else if (num >= 1000000 && num < 1000000000) {
        return parseFloat((num / 1000000).toFixed(2)).toString() + 'M'; // Пример: 1500000 -> 1.56M
    } else if (num >= 1000000000) {
        return parseFloat((num / 1000000000).toFixed(2)).toString() + 'B'; // Пример: 1500000000 -> 1.55B
    }

    return Number(num).toLocaleString('en-US'); // До 10,000 стандартный формат
}


export const emojiConverter = (value) => {
    switch (value) {
        case 1:
            return (
                "1️⃣"
            )
        case 2:
            return (
                "2️⃣"
            )
        case 3:
            return (
                "3️⃣"
            )
        case 4:
            return "4️⃣";
        default:
            return value; // Возвращаем значение по умолчанию, если не найдено соответствие
    }
}

export const getTokenIcon = (type, size = 20) => {
    switch (type) {
        case 'star':
            return  <IconLottyStar style={{ height: size, width: size }} />
        case 'ton':
            return <IconTon style={{ height: size, width: size }} />
        case 'coin':
            return  <IconLottyCoin style={{ height: size, width: size }} />
        case 'telegram_star':
            return <IconTelegramStar
                style={{ height: size, width: size }}
            />
        case 'ticket':
            return  <IconLottyTicket style={{ height: size, width: size }} />
        case 'premium_ticket':
            return  <IconLottyPremiumTicket style={{ height: size, width: size }} />
        default:
            return '';
    }
};