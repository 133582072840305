const IconLottyPremium = ({ duration, ...props }) => {
    let color1, color2;

    if (duration < 1) {
        color1 = "#929292";
        color2 = "#EFA7F7";
    } else if (duration >= 1 && duration < 30) {
        color1 = "#FFED4B";
        color2 = "#A38002";
    } else {
        color1 = "#C83905";
        color2 = "#B36725";
    }

    return (
        <svg {...props} viewBox="0 0 776 679" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M529.342 313.368C487.769 200.666 417.872 20.4315 417.872 20.4315C407.352 -6.81051 368.656 -6.81051 358.132 20.4315C358.132 20.4315 288.23 200.666 243.887 313.368C222.654 367.336 185.686 329.861 185.686 329.861L51.2994 225.463C26.4487 206.897 -7.71172 230.939 1.55394 260.471L31.1535 335.291C43.4153 363.983 50.2114 397.145 77.6001 415.076C87.0467 421.261 101.957 424.889 127.445 419.818C141.606 417 308.097 368.191 349.202 354.601C399.087 338.108 396.316 373.89 368.602 388.825C285.827 433.43 169.561 455.563 138.571 558.017C128.058 592.777 155.201 678.966 210.63 678.966C266.058 678.966 295.689 634.984 388.002 634.984C428.983 634.984 496.086 666.263 504.403 670.719C512.719 675.176 584.771 698.208 623.571 640.482C655.887 592.397 774.445 260.471 774.445 260.471C783.713 230.939 749.553 206.897 724.695 225.463L587.543 329.861C587.543 329.861 548.747 365.964 529.342 313.368Z"
                fill={`url(#lotty_premium_${props.user_id})`}
            />
            <defs>
                <linearGradient id={`lotty_premium_${props.user_id}`} x1="776" y1="89.5686" x2="-0.0888548" y2="589.294" gradientUnits="userSpaceOnUse">
                    <stop stopColor={color1} />
                    <stop offset="1" stopColor={color2} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default IconLottyPremium