import {Badge, Button} from "@telegram-apps/telegram-ui";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getRaffleWinners } from "../../slices/raffles/thunk";
import { getMedal, getPrize } from "../../helpers/raffle_helper";
import { openTgUser } from "../../helpers/telegram_app_helper";
import {motion} from 'framer-motion'
import IconTelegramPremium from "../../Components/icons/IconTelegramPremium";
import IconLottyPremium from "../../Components/icons/IconLottyPremium";
import {FaQuestionCircle} from "react-icons/fa";
import {setErrorTitle, setShowErrorModal} from "../../slices/app/reducer";
const WinnerTable = (props) => {
    const dispatch = useDispatch();
    const { loading, winners, winnersMeta } = useSelector((state) => state.Raffles);
    const { selectedRaffle } = props;


    useEffect(() => {
        dispatch(getRaffleWinners(1, selectedRaffle));
    }, [dispatch, selectedRaffle]);

    const showMore = () => {
        dispatch(getRaffleWinners(winnersMeta.current_page + 1, selectedRaffle, true));
    };

    const prizes = selectedRaffle.matrix;
    const current_user_win = selectedRaffle?.current_user_win;

    const openDetailWindow = (desc) => {
        dispatch(setErrorTitle('Info'))
        dispatch(setShowErrorModal(desc))
    }

    return (
        <div className="fiat-winner-table winner-scroll d-flex w-100 p-2 flex-column">
            {/*{loading && <Spinner size={"s"} />} /!* Отображение лоадера *!/*/}

            <table className={"w-100"}>
                <tbody>
                        {winners &&
                            winners.map((el, i) => (
                                <tr>
                                    <td className={`d-flex ${el.is_disqualified ? 'disqualified' : ''} gap-1`}>
                                        <span className={"d-flex"}>
                                            {getMedal(el.place, prizes, el)}
                                            <span className={el.payment_type == 'telegram_stars' ? 'golden-color' : ''}>
                                                 {el.place}.
                                            </span>
                                        </span>
                                        <span className={"d-flex align-items-center gap-1"}>
                                            <span className={"d-block text-truncate"} style={{
                                                maxWidth: 150
                                            }}>
                                                {openTgUser(el?.user, el.payment_type)}
                                            </span>
                                            {el?.profile?.telegram_premium && <IconTelegramPremium style={{
                                                width: 15
                                            }} />}
                                            {
                                                el?.profile.lotty_premium && (
                                                    <IconLottyPremium
                                                        key={el.user.telegram_id}
                                                        user_id={el.user.telegram_id}
                                                        duration={el?.profile?.lotty_premium_duration}
                                                        style={{
                                                            width: 16
                                                        }}
                                                    />
                                                )
                                            }
                                            {current_user_win && current_user_win.place === el.place && (
                                                <Badge type={"number"}>You</Badge>
                                            )}
                                            {el.is_disqualified &&
                                                <FaQuestionCircle size={14} className={"question-icon"} onClick={() => openDetailWindow('Due to the lack of a completed task, this user was disqualified')} />
                                            }
                                        </span>
                                    </td>
                                    <td className={`${el.is_disqualified ? 'disqualified' : ''}`}>{getPrize(el.prize.type, el.amount)}</td>
                                </tr>
                            ))}
                </tbody>
            </table>

            {winnersMeta?.last_page > winnersMeta?.current_page && (
                <div className={"pagination d-flex justify-content-center pt-2"}>
                    <Button loading={loading} onClick={showMore} mode={"plain"}>
                        Show more
                    </Button>
                </div>
            )}
        </div>
    );
};

WinnerTable.whyDidYouRender = true;

export default WinnerTable;