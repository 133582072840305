import ModalRaffleWinner from "./ModalRaffleWinner";
import ModalRaffleFinished from "./ModalRaffleFinished";
import ModalRaffleParticipated from "./ModalRaffleParticipated";
import ModalRaffle from "./ModalRaffle";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRaffle} from "../../../../slices/raffles/reducer";
import {setErrorTitle, setShowErrorModal} from "../../../../slices/app/reducer";

const RaffleModals = () => {
    const {selectedRaffle} = useSelector(state => state.Raffles)
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)


    useEffect(() => {
        if (selectedRaffle) {
            setIsOpen(true)
        }
    }, [selectedRaffle]);
    const onModalClose = () => {
        setIsOpen(false)
        dispatch(setSelectedRaffle(false))
    }

    const notificationModalOpen = (text) => {
        dispatch(setShowErrorModal('The Premium Lotty stands out from regular ones with bigger prizes and the requirement to have Premium Tickets to participate.'))
        dispatch(setErrorTitle('Info'))
    }

    const getModalComponent = () => {
        if (selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleWinner
                    premiumModalNotification={notificationModalOpen}
                    modalClose={onModalClose}
                    isOpen={isOpen}
                />
            );
        }
        else if (selectedRaffle?.is_closed && !selectedRaffle?.is_participating) {
            return (
                <ModalRaffleFinished
                    premiumModalNotification={notificationModalOpen}
                    modalClose={onModalClose}
                    isOpen={isOpen}
                />
            );
        }

        else if (!selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleParticipated
                    premiumModalNotification={notificationModalOpen}
                    modalClose={onModalClose}
                    isOpen={isOpen}
                />
            );
        }

        return (
            <ModalRaffle
                premiumModalNotification={notificationModalOpen}
                modalClose={onModalClose}
                isOpen={isOpen}
            />
        );
    };

    return (
        isOpen && getModalComponent()
    )
}
export default RaffleModals