import {getTasksApi} from "../../helpers/real_api_helper";
import {setDailyTasks, setShowSkeleton, setTasks} from "./reducer";

export const getTasks = () => async (dispatch) => {
    try {
        dispatch(setShowSkeleton(true))
        getTasksApi()
            .then(res => {
                // Фильтруем задачи: однажды выполняемые и ежедневные
                let oneTimeTasks = [...res.data.filter(el => !el.is_daily && el.is_available)];
                let dailyTasks = [
                    ...res.data.filter(el => el.is_daily)
                ]
                    // Сначала сортируем по доступности: сначала доступные, потом недоступные
                    .sort((a, b) => {
                        if (a.is_available !== b.is_available) {
                            return a.is_available ? -1 : 1; // Сортировка по доступности
                        }
                        return b.reward - a.reward; // Если доступность одинаковая, сортируем по reward
                    })
                    .sort((a, b) => {
                        if (a.reward_type === "premium_ticket" && b.reward_type !== "premium_ticket") {
                            return -1;
                        }
                        if (a.reward_type !== "premium_ticket" && b.reward_type === "premium_ticket") {
                            return 1;
                        }
                        if (a.reward_type === "ticket" && b.reward_type !== "ticket") {
                            return -1;
                        }
                        if (a.reward_type !== "ticket" && b.reward_type === "ticket") {
                            return 1;
                        }
                        return 0;
                    });

                // Отправляем в Redux
                dispatch(setTasks(oneTimeTasks));
                dispatch(setDailyTasks(dailyTasks));
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setShowSkeleton(false));
            });
    } catch (error) {
        console.log(error);
    }
};

export const updateTasks = (task, tasks, dailyTasks) => async (dispatch) => {
    const updatedTasks = [...tasks
        .map(el => {
            if (el.id === task.id) {
                return {
                    ...el,
                    ...task,
                };
            }
            return el;
        })
        // Удаляем задачи, у которых is_available === false
        .filter(el => el.is_available !== false)]
        dispatch(setTasks(updatedTasks));

    if (task?.is_daily) {
        const updatedDailyTasks = [...dailyTasks
            .map(el => {
                if (el.id === task.id) {
                    return {
                        ...el,
                        ...task,
                    };
                }
                return el;
            })
            // Сортируем задачи с is_available === true в начале
            .sort((a, b) => (a.is_available === b.is_available ? 0 : a.is_available ? -1 : 1))];
        if (updatedDailyTasks.length)
            dispatch(setDailyTasks(updatedDailyTasks))
    }
};

export const pushTask = (task, tasks, dailyTasks) => async (dispatch) => {
    const isTaskExists = tasks.some(existingTask => existingTask.id === task.id);

    if (!isTaskExists) {
        let updatedTasks = [...tasks];
        updatedTasks.unshift(task); // Добавляем новый розыгрыш в начало
        dispatch(setTasks(updatedTasks));
    }

    if (task?.is_daily) {
        const isDailyTaskExists = dailyTasks.some(existingTask => existingTask.id === task.id);

        if (!isDailyTaskExists) {
            let updatedDailyTasks = [...dailyTasks];
            updatedDailyTasks.unshift(task); // Добавляем новый топ-розыгрыш в начало
            dispatch(setDailyTasks(updatedDailyTasks));
        }
    }
}