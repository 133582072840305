import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    user: null,
    avatar: null,
    error: false,
    loaded: false
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.user = action.payload
            state.loaded = true
        },
        loginError(state, action) {
            state.error = action.payload
            state.loaded = true
        },
        setAvatar(state, action) {
            state.avatar = action.payload
        }
    }
})

export const {
    loginSuccess,
    loginError,
    setAvatar
} = authSlice.actions

export default authSlice.reducer