import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    topUsers: null,
    loaded: false,
    currentUser: null,
    topWinners: null,
    userWinInfo: null,
    userStatInfo: null,
    winnersLoading: false,
    superBoss: null
}

const appSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setTopUsers(state, action) {
            state.topUsers = action.payload
            state.loaded = true
        },
        setTopWinners(state, action) {
            state.topWinners = action.payload
            state.loaded = true
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload
        },
        setUserWinInfo(state, action) {
            state.userWinInfo = action.payload
        },
        setUserStatInfo(state, action) {
            state.userStatInfo = action.payload
        },
        setWinnersLoading(state, action) {
            state.winnersLoading = action.payload
        },
        setSuperBoss(state, action) {
            state.superBoss = action.payload
        }
    }
})

export const {
    setTopUsers,
    setTopWinners,
    setCurrentUser,
    setUserWinInfo,
    setUserStatInfo,
    setWinnersLoading,
    setSuperBoss

} = appSlice.actions

export default appSlice.reducer