const IconTelegramPremium = (props) => {
    return (
        <svg {...props} viewBox="0 0 787 756" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M414.946 404.352C414.946 410.144 412.587 414.219 407.869 417.008C357.902 445.966 303.861 474.281 245.744 501.953C195.563 525.978 165.969 565.448 157.391 620.362C153.745 643.314 150.957 665.837 149.027 687.717C143.666 747.136 168.757 764.082 223.87 738.77C270.621 717.534 317.8 693.938 365.837 667.983C381.921 659.188 401.221 659.188 417.305 667.983L569.78 750.354C593.155 762.795 622.32 754 634.758 730.619C639.262 722.038 641.192 712.171 640.12 702.518L616.744 505.171C615.458 495.304 618.889 485.436 625.966 478.143L776.939 327.129C785.088 318.978 788.52 307.394 786.375 296.24C781.657 272.644 766.217 259.345 740.268 255.912C677.649 247.761 617.388 240.253 559.701 233.389C543.188 231.458 528.605 221.377 521.314 206.361L427.599 21.455C417.734 2.14932 394.359 -5.57271 375.058 4.29466C367.767 7.94129 361.762 13.9474 357.902 21.2406L259.898 210.008C252.178 224.809 237.595 234.462 221.297 235.749C166.183 240.468 111.498 247.547 57.242 256.556C-6.87885 267.281 -17.1725 296.883 26.5755 345.362C38.3703 358.662 51.8808 371.532 66.8924 383.973C107.209 417.651 153.531 430.737 205.642 423.444L405.296 395.772C410.014 395.128 414.303 398.346 414.946 403.28C414.946 403.709 414.946 404.137 414.946 404.352Z" fill="url(#paint0_linear_1271_21)"/>
            <defs>
                <linearGradient id="paint0_linear_1271_21" x1="92" y1="607" x2="654.5" y2="220" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3893FF"/>
                    <stop offset="1" stopColor="#EE53FE"/>
                </linearGradient>
            </defs>
        </svg>

    )
}
export default IconTelegramPremium